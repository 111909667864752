import React from 'react'
import { getChildrenOrHTMLProp } from '../../library/utils'

function OnlyTextSection({heading, content}) {
  return (
    <>
      <section className='flex flex-col justify-start items-center p-5 lg:p-24'>
        <h1 className='mt-10'>{heading}</h1>
        <div {...getChildrenOrHTMLProp(content)}>{}</div>
      </section>
    </>
  )
}

export default OnlyTextSection
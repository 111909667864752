import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import MainLayout from './Layouts/MainLayout'
import { Header, Heading } from '../components'
import Footer from '../components/organisms/Footer'
import HeroWithDescription from '../components/sections/HeroWithDescription'
import SimpleHeadingWithText from '../components/sections/SimpleHeadingWithText'
import BannerCheckList from '../components/sections/BannerCheckList'
import Contact from './HomePage/Contact'
import OnlyTextSection from '../components/sections/OnlyTextSection'

const HeadingAdapter = ({text, ...props}) => {
  return <section className="section-padding">
    <Heading {...props}>{text}</Heading>
  </section>
}

const mapSectionToComponent = (sectionName, sectionProps, key) => {
  const components = {
    heroWithDescription: HeroWithDescription,
    simpleHeadingWithText: SimpleHeadingWithText,
    bannerCheckList: BannerCheckList,
    heading: HeadingAdapter,
    contact: Contact,
    onlyTextSection: OnlyTextSection
  }

  const Component = components[sectionName] ? components[sectionName] : 'div'
  return <Component key={key} {...sectionProps}/>
}

export const DynamicPageTemplate = ({
  sections
  }) => {
    return (
      <>
        {Object.keys(sections).filter(keySection => !!sections[keySection]).sort((previousSectionName, nextSectionName) => {
          if(!sections[previousSectionName] || !sections[nextSectionName]) return 0

          const previousPosition = (sections[previousSectionName].position || 1 )
          const nextPosition = (sections[nextSectionName].position || 1 )

          return previousPosition - nextPosition
        }).map((sectionName, index) => mapSectionToComponent(sectionName, sections[sectionName], index))}
      </>
    )
}

DynamicPageTemplate.propTypes = {
}

const DynamicPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const additionalProps = {}
  const menuName = frontmatter.menu ? frontmatter.menu: 'main'
  const topLabelContent = frontmatter.header.topContent ? frontmatter.header.topContent : null
  const menuFixed = frontmatter.header.fixed ? frontmatter.header.fixed : true

  return <MainLayout seo={frontmatter.seo}>
    <Header menu={menuName} topLabelContent={topLabelContent} fixed={menuFixed}></Header>
    <DynamicPageTemplate
        title={frontmatter.title}
        path={frontmatter.path}
        sections={frontmatter.sections}
      />
    <Footer></Footer>
  </MainLayout>
}

DynamicPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.object,
    }),
  }),
}

export default DynamicPage

export const DynamicPageQuery = graphql`
  query DynamicPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        page_class
        pathname
        header{
          topContent
          fixed
          heroTitle
          heroDescription
          heroCTAText
          heroImage{
            image{
              childImageSharp {
                fluid{
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        sections{
          heroWithDescription{
            position
            backgroundImg{
              childImageSharp {
                fluid{
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            heading
            afterHeading
            description
          }
          onlyTextSection{
            position
            heading
            content
          }

#          simpleHeadingWithText{
#            position
#            heading
#            text
#          }
#
#          bannerCheckList{
#            backgroundImg{
#              childImageSharp {
#                fluid{
#                  ...GatsbyImageSharpFluid
#                }
#              }
#              publicURL
#            }
#            position
#            heading
#            list
#            ctaLink
#            ctaText
#          }

#          heading{
#            position
#            text
#            h1
#            className
#          }

          contact{
            position
            title
          }

        }

#        custom{
#          css{
#            general
#            sm
#            md
#            lg
#            xl
#            xl2
#            external  
#          }
#          javascript{
#            external
#          }
#        }


        seo{
          title
          description
          keywords
          language
          canonical
          #schemas{
          #    schemas
          #}
          lang
        }
      }
    }
  }
`

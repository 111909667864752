import React from 'react'
import PropTypes from 'prop-types'
import { getAllowedClasses, getChildrenOrHTMLProp, getRealSrc } from '../../library/utils'
import { Heading, Paragraph } from '..'

const HeroWithDescription = ({heading, afterHeading, description, backgroundImg}) => {
    const srcImage = getRealSrc(backgroundImg)
    const classList = getAllowedClasses({
        'hero-with-description': true,
        'section-padding': true,
    })
    
    return <section className={classList} style={{backgroundImage: `url(${srcImage})`}}>
        <div className="hero-with-description__main">
            <Heading h1 className="hero-with-description__heading">{heading}</Heading>
            <Paragraph className="hero-with-description__after_heading">{afterHeading}</Paragraph>
        </div>
        <div className="hero-with-description__description-container">
            <Paragraph className="hero-with-description__description">{description}</Paragraph>
        </div>
    </section>
}

HeroWithDescription.propTypes = {

}

export default HeroWithDescription


import React from 'react'
import PropTypes from 'prop-types'
import { getAllowedClasses, getRealSrc } from '../../library/utils'
import { Button, Heading, Link } from '..'

const CheckSVG = () => {
    return <svg className="banner-check-list__list-check" width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.32204 9.5C-0.265111 18 2.322 26 25.3219 2" stroke="#837FF3" strokeWidth="4" strokeLinecap="round"/>
    </svg>    
}

const BannerCheckList = ({heading, list, backgroundImg, ctaLink, ctaText}) => {
    const srcImage = getRealSrc(backgroundImg)
    
    const classList = getAllowedClasses({
        'banner-check-list': true,
        'section-padding': true,
    })

    return <section className={classList} style={{backgroundImage: `url(${srcImage})`}} >
        <Heading h2 className="banner-check-list__heading">{heading}</Heading>
        <ul className="banner-check-list__list">
            {list.map((item, index) => <li key={index}><CheckSVG/>{item}</li>)}
        </ul>

        <Button link={ctaLink} secondary small>{ ctaText }</Button>
    </section>
}

BannerCheckList.propTypes = {
    heading: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.string),
}

export default BannerCheckList
import React from 'react'
import PropTypes from 'prop-types'
import { getAllowedClasses } from '../../library/utils'
import { Heading, Paragraph } from '..'

const SimpleHeadingWithText = ({heading, text}) => {
    const classList = getAllowedClasses({
        'simple-heading-text': true,
        'section-padding': true,
    })

    return <section className={classList}>
        <Heading h1 className="simple-heading-text__heading">{heading}</Heading>
        <Paragraph className="simple-heading-text__text">{text}</Paragraph>
    </section>
}

SimpleHeadingWithText.propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
}

export default SimpleHeadingWithText